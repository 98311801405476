import React, {useMemo, useEffect} from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import SEO from "../components/seo"
// import {RichText} from 'prismic-reactjs';
import GridSlices from "../components/slices/GridSlices"

export const pageQuery = graphql`
  query Marques($lang: String!){
    prismicPage(lang: {eq: $lang}, data: {template: {eq: "marques"}}) {
      ..._page
    }
  }
`

const IS_BROWSER = typeof window !== 'undefined'

const Marques = ({ path, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    })
  }, [staticData])
  
  const { seo_title, seo_description, seo_image, body } = data.prismicPage.data

  // useEffect(() => {}, [])

  return (
    <div className="page-template marques">
      <SEO
        pageTitle={seo_title}
        pageDescription={seo_description.text}
        pageBanner={seo_image.url}
        template="template-marques"
        pathname={path}
        page
      />
      
      {body && <GridSlices input={body} />}

    </div>
  )
}

export default Marques
